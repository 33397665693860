import * as React from "react"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import { Link } from "gatsby"

const metaTags = [{
                  "attributes": {
                    "content": "404: Not found",
                    "name": "title"
                  }
                }];

const NotFoundPage = () => (
<Layout>
    <Meta tags={metaTags} />
    <main className="innerpage">
        <section className="error_page_404 text-center pt-5">
            <div className="container">
                <div className="img_wrap">
                    <img src="/assets/images/error_404.jpg" alt="" />
                </div>
                <div className="content_404">
                    <h1>That’s an error.</h1>
                    <p className="mt-5">
                        <Link to="/" className="btn btn-primary-solid">
                        Back to Home</Link>
                    </p>
                </div>
            </div>
        </section>
    </main>
</Layout>
)
export default NotFoundPage